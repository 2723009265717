import { useAppRouter } from '@legacy/@core/hooks/UseAppRouter'
import React, { FC, useEffect } from 'react'
import { useAuthenData } from 'store/AuthData'
import { LINK_URL_LOGIN, LINK_URL_CONSULTANTS_DASHBOARD } from 'utilities/LinkURL'

const IndexPage: FC = () => {
  const router = useAppRouter()
  const { isLogined } = useAuthenData()

  useEffect(() => {
    router.push(isLogined ? LINK_URL_CONSULTANTS_DASHBOARD : LINK_URL_LOGIN)
  }, [])

  return <></>
}

export default IndexPage
